import { render, staticRenderFns } from "./AddEstablishment.vue?vue&type=template&id=809a246a"
import script from "./AddEstablishment.vue?vue&type=script&lang=js"
export * from "./AddEstablishment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('809a246a')) {
      api.createRecord('809a246a', component.options)
    } else {
      api.reload('809a246a', component.options)
    }
    module.hot.accept("./AddEstablishment.vue?vue&type=template&id=809a246a", function () {
      api.rerender('809a246a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/establishments/establishmentsDirection/AddEstablishment.vue"
export default component.exports